import { Avatar, Container, Flex, Text, Box, VStack, Heading, Icon, SimpleGrid, Spacer, useColorModeValue, Tooltip } from '@chakra-ui/react'
import { FiKey } from 'react-icons/fi'


export const SuperHeros = () => {

  const SUPERHEROS: SuperHeroCardProps[] = [
    { name: 'Jordan Skole', title: 'VP of FI Product', id: '@jordanskole', isKeyContributor: true, avatar: 'https://pbs.twimg.com/profile_images/1278865883058839553/8NR71HyQ_400x400.jpg' },
    { name: 'Alli Blum', title: 'CX Design Strategist', id: '@alliblum', isKeyContributor: true, avatar: 'https://pbs.twimg.com/profile_images/1113139198977028096/Rgmqa7dw_400x400.png' },
    { name: 'Heidi Kreis', title: 'CX Designer', id: '@heidikreis', isKeyContributor: true },
    { name: 'Joshua Lee', title: 'CX Designer', id: '@joshualee', isKeyContributor: true },
    { name: 'Eric Gould', title: 'CX Operations', id: '@ericgould', isKeyContributor: true, avatar: 'https://pbs.twimg.com/profile_images/425475973958479872/dQfJIve5_400x400.jpeg' },
    { name: 'Jeff Nessler', title: 'CX Operations', id: '@jeffnessler', isKeyContributor: true },
    { name: 'Cristi Vernon', title: 'CX Operations', id: '@cristivernon', isKeyContributor: true },
    { name: 'Andrew Mailey', title: 'CX Engineering', id: '@andrewmailey', isKeyContributor: true, avatar: 'https://bc3-production-assets-cdn.basecamp-static.com/4099738/people/BAhpBC7JNQI=--4280184962599de120a6804a417ef151deb0b130/avatar-800-x2?v=1' },
    { name: 'Eddie Schultz', title: 'CX Engineering', id: '@eddieschultz', isKeyContributor: true, avatar: 'https://bc3-production-assets-cdn.basecamp-static.com/4099738/people/BAhpBDIoOwI=--471d16f1cd215c53032287171866325a548f9cb7/avatar-800-x2?v=1' },
    { name: 'Justin Dickow', title: 'Engineering Consultant', id: '@JuJoDi', isKeyContributor: true, avatar: 'https://pbs.twimg.com/profile_images/1442802220840493056/47TaDI9V_400x400.jpg' },
    { name: 'Joseph Gaiefsky', title: 'Report Consultant', id: '@josephgaifesky' },
    { name: 'Kristina Beirwagon', title: 'FI Consultant', id: '@kristinabeirwagon' },
    { name: 'Ervin Fowlkes', title: 'Product Consultant', id: '@ervinfowlkes', avatar: 'https://pbs.twimg.com/profile_images/619415900429512704/vp3Ycuz4_400x400.jpg' },
  ]

  return (
    <Container maxW={'6xl'} py={12}>
      <VStack align='stretch' spacing={6}>
        <Heading>Superheros:</Heading>
        <Text py={6} size={'lg'}>Special thanks to the following people and projects. Without their help this project would not have been possible.</Text>
        <SimpleGrid columns={3} spacing={10}>
          {SUPERHEROS.map(({ avatar, name, title, id, isKeyContributor }) => <SuperHeroCard avatar={avatar} name={name} title={title} key={id} isKeyContributor={isKeyContributor} />)}
        </SimpleGrid>
      </VStack>
    </Container>
  )
}

interface SuperHeroCardProps {
  avatar?: string
  name: string
  title?: string
  id?: string
  isKeyContributor?: boolean
}

const SuperHeroCard: React.FC<SuperHeroCardProps> = ({ avatar, name, title, isKeyContributor = false }) => {
  return (
    <Flex
      px={{ base: 4 }}
      py={'4'}
      shadow={'md'}
      border={'1px solid'}
      borderColor={useColorModeValue('gray.800', 'gray.500')}
      rounded={'lg'}
    >
      <Avatar src={avatar} name={name} />
      <Box ml='3'>
        <Text fontWeight='bold'>
          {name}
        </Text>
        <Text fontSize='sm'>{title || 'Contributor'}</Text>
      </Box>
      <Spacer />
      {isKeyContributor
        ? (
          <Box justifySelf={'top'}>
            <Tooltip label='Key contributor' hasArrow>
              <span><Icon as={FiKey} /></span>
            </Tooltip>            
          </Box>
        ): null}
    </Flex>
  )
}
