import { SimpleGrid, Box, Heading, Text, FormControl, FormLabel, Input, InputGroup, InputLeftElement, InputLeftAddon, Select, Stack, FormHelperText, useColorModeValue, Flex, Spacer, Button, HStack, useToast, Skeleton } from '@chakra-ui/react'
import { useFormik } from 'formik'
import { FiSettingsProps } from '../routes/dashboard'
import { update } from '../api/fi/update'
import { useEffect } from 'react'

export const FiAdditionalSettings: React.FC<FiSettingsProps> = ({ 
  isLoading, 
  accessToken, 
  fi: { 
    rssdId, 
    primaryColor = '', 
    primaryColorDark = '',
    customerTerm = 'customer',
    internetBankingTerm = '',
    websiteUrl = '',
    logoUrl = '',
  } 
}) => {
  const toast = useToast()
  
  const { values, handleSubmit, handleChange, setValues } = useFormik({
    initialValues: {
      primaryColor: '#003a5d',
      primaryColorDark: '',
      customerTerm: 'customer',
      internetBankingTerm: '',
      websiteUrl: '',
      logoUrl: '',
    },
    onSubmit: (data) => {
      if (accessToken && rssdId) {
        update({ accessToken, rssdId, data })
          .then(fi => toast({
            title: 'Settings saved',
            status: 'success',
            duration: 9000
          }))
          .catch(err => toast({
            title: 'Eeks! Something went wrong updating your settings',
            description: 'We have logged this event and our team is be looking into it. Thanks for your understanding.',
            status: 'warning',
            duration: 9000
          }))
      }
    }
  })

  useEffect(() => {
    setValues({
      primaryColor,
      primaryColorDark,
      customerTerm,
      internetBankingTerm,
      websiteUrl,
      logoUrl,
    })
  }, [primaryColor, primaryColorDark, customerTerm, internetBankingTerm, websiteUrl, logoUrl])


  return (
    <SimpleGrid columns={{sm: 1, lg: 2}}>
        <Box>
          <Heading size='lg' pb={6}>Additional details:</Heading>
          <Text size='lg' pb={6}></Text>
        </Box>
        <Box
          bg={useColorModeValue('white', 'gray.900')}
          boxShadow={'2xl'}
          rounded={'md'}
          p={6}      
          overflow={'hidden'}
          borderWidth='1px' 
          borderRadius='lg'
        > 
          <form onSubmit={handleSubmit}>
            <Stack spacing={4}>
              <HStack> 
                <Box w={'50%'}>
                  <FormControl id="color" isRequired>
                    <FormLabel>Primary color (light mode):</FormLabel>
                    <Skeleton isLoaded={!isLoading}>
                      <InputGroup>
                        <InputLeftElement
                          pointerEvents="none"
                          fontSize="1.6em"
                          // children={}                                        
                        >
                          <Box bg={values.primaryColor || '#003a5d'} h='1em' w='1em' borderRadius='sm' />
                        </InputLeftElement>
                        <Input 
                          id='primaryColor'
                          name='primaryColor'
                          onChange={handleChange}
                          value={values.primaryColor || '#003a5d'}
                          placeholder='#003a5d' /> 
                      </InputGroup>
                    </Skeleton>                  
                    <FormHelperText>Light mode</FormHelperText>
                  </FormControl>
                </Box>
                <Box w={'50%'}>
                  <FormControl id="color" isRequired>
                    <FormLabel>Primary color:</FormLabel>
                    <InputGroup>
                      <InputLeftElement
                        pointerEvents="none"
                        fontSize="1.6em"
                        // children={}                                        
                      >
                        <Box bg={'#003a5d'} h='1em' w='1em' borderRadius='sm' />
                      </InputLeftElement>
                      <Input 
                        placeholder="coming soon!"
                        id='primaryColorDark'
                        name='primaryColorDark'
                        onChange={handleChange}
                        value={values.primaryColorDark || ''}
                        disabled />                    
                    </InputGroup>
                    <FormHelperText>Dark mode (coming soon!)</FormHelperText>
                  </FormControl>
                </Box>
              </HStack>            
              <Box>
                <FormControl id="customerterm" isRequired>
                  <FormLabel>What do you call your customers?</FormLabel>
                  <Skeleton isLoaded={!isLoading}>
                    <Select
                      id='customerTerm'
                      name='customerTerm'
                      onChange={handleChange}
                      value={values.customerTerm || 'customer'}
                    >
                      <option value='customer'>Customers</option>
                      <option value='member'>Members</option>
                    </Select>
                  </Skeleton>                
                </FormControl>
              </Box>
              <Box>
                <FormControl id="ibterm" isRequired>
                  <FormLabel>What do you call internet banking?</FormLabel>
                  <Skeleton isLoaded={!isLoading}>
                    <Input 
                      type='text' 
                      id='internetBankingTerm'
                      name='internetBankingTerm'
                      onChange={handleChange}
                      value={values.internetBankingTerm || ''}
                      placeholder='banno' />
                  </Skeleton>
                </FormControl>
              </Box>
              <Box>
                <FormControl id="customer term" isRequired>
                  <FormLabel>What is your primary website?</FormLabel>
                  <Skeleton isLoaded={!isLoading}>
                    <InputGroup>
                      <InputLeftAddon>https://</InputLeftAddon>
                      <Input 
                        type='text'
                        id='websiteUrl'
                        name='websiteUrl'
                        onChange={handleChange}
                        value={values.websiteUrl || ''}
                        placeholder='mysite'/>
                    </InputGroup>
                  </Skeleton>                
                </FormControl>
              </Box>
              <Box>
                <FormControl id="logourl" isRequired>
                  <FormLabel>Please provide a url to your main logo</FormLabel>
                  <Skeleton isLoaded={!isLoading}>
                    <InputGroup>
                      <InputLeftAddon>https://</InputLeftAddon>
                      <Input 
                        type='text'
                        id='logoUrl'
                        name='logoUrl'
                        onChange={handleChange}
                        value={values.logoUrl || ''}
                        placeholder='mysite.com/logo.svg' />
                    </InputGroup>
                  </Skeleton>
                </FormControl>
              </Box>
              <Flex>
                <Spacer />
                <Button
                  mt={4}
                  colorScheme='teal'
                  type='submit'
                  px={6}
                >
                  Save
                </Button>
              </Flex> 
            </Stack> 
          </form>
        </Box>
      </SimpleGrid>
  )
}