import {
    Flex,
    Box,
    Center,
    Progress,
    Stack,
    Icon,
    Button,
    Heading,
    Text,
    Skeleton,
    useColorModeValue,
    useToast
  } from '@chakra-ui/react';
import { useAuth0 } from '@auth0/auth0-react'
import { useNavigate } from 'react-router-dom'
import { BsFillShieldLockFill } from 'react-icons/bs'
import { FiSend } from 'react-icons/fi'
import { useEffect } from 'react';
import { useInterval } from '@chakra-ui/react'

const { REACT_APP_SMB_UTILITY_API } = process.env

export const VerifyEmail: React.FC = () => {
  const { user, isLoading, getAccessTokenSilently } = useAuth0()
  const navigate = useNavigate()
  const toast = useToast()

  const resendVerificationEmail = async () => {
    console.log('resending');
    
    try {
      const accessToken = await getAccessTokenSilently()
      const res = await fetch(`${REACT_APP_SMB_UTILITY_API}/v1/users`, {
        method: 'POST',
        headers: { Authorization: `Bearer ${accessToken}`},
        body: JSON.stringify({
          resendVerificationEmail: true
        })
      })
      const json = await res.json()
    } catch (e) {
      console.error(e);
    }
  }

  // useInterval(() => {
    // check for verified email status    
  // }, 5000)



  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}>
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
        <Stack align={'center'}>
          <Heading fontSize={'2xl'}>Welcome to FI Product Hub!</Heading>            
        </Stack>
        <Box
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          p={8}>
          <Stack spacing={4}>
            <Center>              
              <Icon as={BsFillShieldLockFill} w={32} h={32} />
            </Center>
            <Progress size='xs' isIndeterminate />
            <Stack spacing={4}>
              <Text fontSize={'md'}>We can&apos;t wait to get you in and show you around, but we take security very seriously around here.</Text>
              <Text fontSize={'md'}>Please click on the link in the welcome email we sent to {user?.email || 'you'} in order to access the rest of the FI Product Hub.</Text>
              <Button 
                colorScheme={'cyan'} 
                rightIcon={<Icon as={FiSend} />} 
                onClick={() => resendVerificationEmail()}>
                Resend email
              </Button>
            </Stack>
            <Text fontSize={'xs'} m='auto'>
                Click here to resend this verification email, if needed.
            </Text>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}