import React from 'react'
import { Container, Heading, VStack } from '@chakra-ui/react'
import { NotificationCard, NotificationCardProps } from '../../components'

const NOTIFICATIONS: NotificationCardProps[] = [
  { category: 'event', title: 'Come see us at AFT', description: 'Come visit us at the Autobooks booth to pick up your custom \'MoneyMap\' to learn where all of your deposits have been hiding', isRead: false },
  { category: 'report', title: 'Your February Autobooks SMB report is ready', description: 'The Autobooks SMB report contains a list of all your small businesses active on the Autobooks platform including enrollment date and deboard status', isRead: false }
]


export const Notifications = () => {
  return(
    <Container maxW={'6xl'} py={12}>
      <Heading py={12}>Notifications:</Heading>
      <VStack spacing={6}>
        {NOTIFICATIONS.map((n, i) => <NotificationCard key={i} category={n.category} title={n.title} description={n.description} isRead={n.isRead}/>)}
      </VStack>
    </Container>
  )
}