import React from 'react'
import { Container } from '@chakra-ui/react'
import { BasicReportsTable, PremiumReportsTable } from '../../components'

export const Reports: React.FC = () => {
  return (
    <Container maxW={'6xl'} py={12}>
      <PremiumReportsTable />
      <BasicReportsTable />
    </Container>
  )
}

