import { SimpleGrid } from '@chakra-ui/react'
import { StatsCard } from './StatsCard'
import { useAuth0 } from '@auth0/auth0-react'
import { useState, useMemo } from 'react'

const { REACT_APP_SMB_UTILITY_API, REACT_APP_AUTH0_NAMESPACE } = process.env

export const DailyStats = () => {
  const [state, setState] = useState({ isLoading: true, todayStats: {
    enrollmentsAllTime: 0,
    enrollmentsLast30Days: 0,
    processingLast30Days: 0
  }, dailyStats: [], isAuthorized: true })
  const { user, isLoading, getAccessTokenSilently, getIdTokenClaims } = useAuth0()

  useMemo(() => {
    const getDailyStats = async () => {
      try {

        const accessToken = await getAccessTokenSilently()
        
        const dailyStatsResponse = await fetch(`${REACT_APP_SMB_UTILITY_API}/v1/fi/${user && user[`${REACT_APP_AUTH0_NAMESPACE}/rssd_id`]}/stats`, {
          headers: {
            'authorization': `Bearer ${accessToken}`          
          },
          method: 'GET'
        });

        console.log(dailyStatsResponse)

        if (dailyStatsResponse.status === 401) {
          setState({
            ...state,
            isLoading: false,
            isAuthorized: false
          })
        } else {


          const { dailyStats }  = await dailyStatsResponse.json()
        
          setState({
            ...state,
            dailyStats,
            isLoading: false,            
            todayStats: dailyStats[0]
          })
        }
        

      } catch (e) {
        console.log(e)
      }      
    }    
    getDailyStats()
  },[getAccessTokenSilently, isLoading])

  console.log(state);
  

  return (
    <SimpleGrid columns={{ base: 1, lg: 3 }} spacing={{ base: 5, lg: 8 }}>
      <StatsCard title={'All time:'} stat={state.todayStats.enrollmentsAllTime} metric={'Enrollments'} isLoading={state.isLoading}  />
      <StatsCard title={'Last 30d:'} stat={state.todayStats.enrollmentsLast30Days} metric={'Enrollments'} isLoading={state.isLoading}  />
      <StatsCard title={'Last 30d:'} stat={state.todayStats.processingLast30Days} metric={'Processing SMBs'} isLoading={state.isLoading}  />
    </SimpleGrid>
  )
}

// /{env}/fi/{rssdId}/stats