import { useMemo, useState } from 'react'
import { Container, Divider, Heading } from '@chakra-ui/react'
import { FiBasicSettings, FiAddressSettings, FiAdditionalSettings } from '../../components'
import { useAuth0 } from '@auth0/auth0-react'
import { IFinancialInstitution } from '../../api/fi'

const { REACT_APP_AUTH0_DOMAIN, REACT_APP_SMB_UTILITY_API, REACT_APP_AUTH0_NAMESPACE } = process.env

// see mirror comment on utils/updateFinancialInstitution
export interface FiSettingsProps {
  isLoading:                boolean
  fi: IFinancialInstitution
  accessToken?:             string
}

export const Settings = () => {  
  const [state, setState] = useState<{ isLoading: boolean; fi: any; accessToken?: string }>({ isLoading: true, fi: {}, })
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0()
  
  // we need to hoist this up into a useToken() context
  // and then grab the api.fi.read 
  // but this is fine for now
  // because it works 
  // and also because REST can RIP...
  // :face_palm:
  const getFinancialInstitutionDetails = async () => {        

    const accessToken = await getAccessTokenSilently()

    const fiRes = await fetch(`${REACT_APP_SMB_UTILITY_API}/v1/fi/${user && user[`${REACT_APP_AUTH0_NAMESPACE}/rssd_id`]}?token=${accessToken}`) 
    const fi = await fiRes.json()

    setState({
      accessToken,
      fi,
      isLoading: false      
    })

  }

  

  // hoist this up the app using context 
  useMemo(() => {
    getAccessTokenSilently()
    
    getFinancialInstitutionDetails()
  }, [isAuthenticated])  

  return (
    <Container maxW={'6xl'} py={12}>
      <Heading pb={12}>Settings:</Heading>
      <FiBasicSettings isLoading={state.isLoading} fi={state.fi} accessToken={state.accessToken} />
      <Divider my={12} />
      <FiAddressSettings isLoading={state.isLoading} fi={state.fi} accessToken={state.accessToken} />
      <Divider my={12} />
      <FiAdditionalSettings isLoading={state.isLoading} fi={state.fi} accessToken={state.accessToken} />
    </Container>
  )
}