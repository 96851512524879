import React, { ReactText, useEffect, useState } from 'react'
import {
  Avatar,
  Box,
  BoxProps,
  CloseButton,
  Drawer,
  DrawerContent,
  Flex,
  FlexProps,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Spacer,
  Text,
  VStack,
  useColorModeValue,
  useDisclosure,
  useBreakpointValue
} from '@chakra-ui/react'
import {
  FiBell,
  FiBook,
  FiChevronDown,
  FiHome,
  FiMenu,
  FiTrendingUp,
  FiAward,
  FiFilter,
  FiZap, 
  FiSettings, 
  FiUser,
  FiLogOut,
  FiLifeBuoy
} from 'react-icons/fi'
import { IconType } from 'react-icons'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

import { Link } from '../../components'

interface LinkItemProps {
  name: string;
  icon: IconType;
  path: string;
}
const LinkItems: Array<LinkItemProps> = [
  { name: 'Home', icon: FiHome, path: '' }, 
  { name: 'Reports', icon: FiTrendingUp, path: 'reports' }, 
  { name: 'Sales tools', icon: FiAward, path: 'products' }, 
  { name: 'Marketing tools', icon: FiFilter, path: 'snippets' },
  { name: 'Training', icon: FiBook, path: 'training' },
  { name: 'Support', icon: FiLifeBuoy, path: 'support' }
]

export const AuthLayout: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Box minH="100vh" bg={useColorModeValue('white', 'gray.900')}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full">
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        <Outlet />
      </Box>
    </Box>
  )
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue('white', 'gray.900')}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 60 }}
      pos="fixed"
      h="full"
      {...rest}>
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Text fontSize="2xl" fontWeight="bold">
          Autobooks
        </Text>
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      {LinkItems.map((link) => (
        <NavItem key={link.name} icon={link.icon} path={link.path}>
          {link.name}
        </NavItem>
      ))}
    </Box>
  )
}

interface NavItemProps extends FlexProps {
  icon: IconType;
  children: ReactText;
  path: string;
}
const NavItem = ({ icon, path, children, ...rest }: NavItemProps) => {
  return (
    <Link to={path} style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: 'cyan.400',
          color: 'white',
        }}
        {...rest}>
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: 'white',
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  )
}


interface MobileProps extends FlexProps {
  onOpen: () => void;
}

const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  const showSupportDisclosure = useBreakpointValue({ base: false, md: true })
  const [currentRoute, setCurrentRoute] = useState<{ isLoading: boolean; pageName: string}>({
    isLoading: true,
    pageName: 'Home',
  })
  const { logout, user } = useAuth0()
  const navigate = useNavigate()
  const location = useLocation()
  const item = LinkItems.find(item => `/dashboard/${item.path}` === location.pathname)
  
  
  useEffect(() => {
    setCurrentRoute({ 
      pageName: item?.name || 'Home',
      isLoading: false      
    })
  }, [location])

  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent={{ base: 'space-between', md: 'flex-end' }}
      {...rest}>
      {showSupportDisclosure
        ? (
          <IconButton
            display={{ base: 'flex', md: 'none' }}
            onClick={onOpen}
            variant="outline"
            aria-label="open menu"
            icon={<FiMenu />}
          />
        ) : null 
      }
      <Text
        display={{ base: 'flex' }}
        fontSize="xl"
        fontWeight="bold">
        {currentRoute.pageName}
      </Text>
      <Spacer />
      <Text
        display={{ base: 'flex', md: 'none' }}
        fontSize="2xl"
        fontWeight="bold">
        Autobooks
      </Text>

      <HStack spacing={{ base: '0', md: '6' }}>
        <IconButton
          size="lg"
          variant="ghost"
          aria-label="open menu"
          icon={<FiLifeBuoy />}
          onClick={() => navigate('notifications')}
        />
        <IconButton
          size="lg"
          variant="ghost"
          aria-label="open menu"
          icon={<FiBell />}
          onClick={() => navigate('notifications')}
          colorScheme={location.pathname === '/dashboard/notifications' ? undefined : 'red'}
        />
        <Flex alignItems={'center'}>
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: 'none' }}>
              <HStack>
                <Avatar
                  size={'sm'}
                  src={user?.picture}
                />
                <VStack
                  display={{ base: 'none', md: 'flex' }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2">
                  <Text fontSize="sm">{user?.name}</Text>
                  <Text fontSize="xs" color="gray.600">
                    Admin
                  </Text>
                </VStack>
                <Box display={{ base: 'none', md: 'flex' }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue('white', 'gray.900')}
              borderColor={useColorModeValue('gray.200', 'gray.700')}>
              <MenuItem onClick={() => navigate('/dashboard/profile')}>
                <Icon as={FiUser} mr={2} />
                Profile
              </MenuItem>
              <MenuItem onClick={() => navigate('/dashboard/settings')}>
                <Icon as={FiSettings} mr={2} />
                Settings
              </MenuItem>
              <MenuItem onClick={() => navigate('/dashboard/superheros')}>
                <Icon as={FiZap} mr={2} />
                Superheros
              </MenuItem>
              <MenuDivider />
              <MenuItem onClick={() => logout({ returnTo: window.location.origin })}>
                <Icon as={FiLogOut} mr={2} />
                Sign out
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  )
}