import {
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  Progress,
  VStack,
  useColorModeValue, 
  Skeleton,
  Tooltip
} from '@chakra-ui/react'

interface StatsCardProps {
  title: string
  stat: number
  metric: string
  isLoading?: boolean
  goal?: number
  progress?: number
  asOf?: Date
}

export const StatsCard:React.FC<StatsCardProps> = ({ title, stat, metric, isLoading, goal, progress }) => {
  return (
    <Stat
      px={{ base: 4, md: 8 }}
      py={'5'}
      shadow={'xl'}
      border={'1px solid'}
      borderColor={useColorModeValue('gray.800', 'gray.500')}
      rounded={'lg'}>
      <StatLabel fontWeight={'medium'} isTruncated>
        {title}
      </StatLabel>
      <Skeleton isLoaded={!isLoading}>
        <StatNumber fontSize={'2xl'} fontWeight={'medium'}>
          {stat.toLocaleString()} {metric}
        </StatNumber>
      </Skeleton>      
      {progress
        ? (
          <StatHelpText pt={6}>
            <Progress colorScheme={progress > 80 ? 'green' : 'orange'} size='xs' value={progress} mb={2} />
            4 away from goal of 25
          </StatHelpText>
        ) : null
      }
    </Stat>
  );
}