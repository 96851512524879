const { REACT_APP_SMB_UTILITY_API } = process.env

export interface ReadArgs {
  token: string  
}

export const read = async ({ token } : ReadArgs) => {
  const res = await fetch(`${REACT_APP_SMB_UTILITY_API}/v1/tickets`, {
    method: 'get', 
    headers: {
      authorization: `Bearer ${token}`
    }
  })
  return await res.json()
}