import { Center, CircularProgress, Heading, Progress, Image, Flex, VStack, Text } from "@chakra-ui/react"

export const Loading: React.FC = () => {
    return (
      <Center py={6} height={'100vh'}>
        <VStack>

        </VStack>
      </Center>
    )
}

  