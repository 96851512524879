import { AspectRatio, Avatar, Badge, Box, Button, Heading, HStack, Flex, Image, Spacer, Stack, Text, useColorModeValue } from '@chakra-ui/react'
import { FiExternalLink } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'

export interface ProductCardProps {
  image?: string
  title: string
  description: string
  tags?: string[]
  slug?: string
  length?: 'short' | 'medium' | 'long' | 'bootcamp'
  cta?: string
}

export const ProductCard: React.FC<ProductCardProps> = ({ description, image, slug,  tags, title, length, cta = 'learn more' }) => {
  
  const navigate = useNavigate()
  const _buttonClick = ({ slug, href }: { slug?: string, href?: string}) => {
    if (slug) {
      return navigate(slug)
    }
    window.open(href, '_blank')
  }

  const LEVEL_THEME = {
    'getting started': 'green.500',
    'beginner': 'blue.500',
    'intermediate': 'yellow.500',
    'advanced': 'orange.500'
  }

  return (
    <Flex
      direction={'column'}
      bg={useColorModeValue('white', 'gray.900')}
      boxShadow={'2xl'}
      rounded={'md'}
      p={6}      
      overflow={'hidden'}
      borderWidth='1px' 
      borderRadius='lg'>      
      <AspectRatio ratio={16 / 9} mx={-6} mt={-6}>
        <Image
          src={image}
          fit='cover'
          align='bottom'
        />
      </AspectRatio>
      <Flex direction={'column'} >
        <Stack spacing={4}>
          <HStack pt={6}>
            {tags && tags.map((tag, i) => (
              <Badge
                key={i}
                color={'orange.500'}
                textTransform={'uppercase'}
                fontWeight={800}
                fontSize={'sm'}
                letterSpacing={1.1}>
                {tag}
              </Badge>
            ))}
          </HStack>          
          <Heading
            color={useColorModeValue('gray.700', 'white')}
            fontSize={'2xl'}
            fontFamily={'body'}>
            {title}
          </Heading>                       
          <Text color={'gray.500'} noOfLines={2}>
            {description}
          </Text>               
        </Stack>
        <Spacer />        
        <Stack pt={6}>
          <Button onClick={() => navigate(slug || '#')} colorScheme={'teal'}>{cta}</Button>     
        </Stack>
      </Flex>      
    </Flex>
  )
}