import { useState, useEffect } from 'react'
import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  Heading,
  Text,
  Input,
  SimpleGrid,
  Stack,
  useColorModeValue,
  FormLabel,
  useToast
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { useFormik } from 'formik'
import { useStep } from 'usehooks-ts'
import { update } from '../../api/user/update'

export const Welcome = () => {
  const [isLoadingLocal, setIsLoadingLocal] = useState(true)
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0()
  const [currentStep, helpers] = useStep(3)
  const navigate = useNavigate()
  
  const namespace  = process.env.REACT_APP_AUTH0_NAMESPACE
  
  const {
    canGoToPrevStep,
    canGoToNextStep,
    goToNextStep,
    goToPrevStep,
    reset,
    setStep,
  } = helpers  
  
  useEffect(() => {
      async function checkUser() {
          if (!isLoading && !isAuthenticated) {
              loginWithRedirect();
          } else {
              setIsLoadingLocal(false)
          }
      }
      checkUser();
  }, [user, isAuthenticated, isLoading, loginWithRedirect])

  if (!isLoadingLocal && !isLoading && isAuthenticated) {
    if(!user?.email_verified) {
      navigate('/onboarding/verify-email')
    }
  }

  
  
  return (
    <Flex 
      minH={'100vh'}
      align={'center'}
      justify={'center'}>
      <Container maxW='6xl' py={12} textAlign={currentStep === 1 ? 'center' : undefined}>
        {currentStep === 1
          ? (
            <>
              <Stack>
                <Heading textAlign={'center'}>Welcome to the FI Product Hub by Autobooks!</Heading>
                <Text fontSize='2xl' textAlign={'center'}>We&apos;re so glad you are here! We&apos;d like to personal your experience, but before we do so, we&apos;d like to ask you a few questions.</Text>
                <Text fontSize='2xl' textAlign={'center'}>Ready?</Text>          
              </Stack>
              <Button h={20} fontSize='2xl' colorScheme={'cyan'} px={20} mt={20} onClick={goToNextStep}> Let&apos;s do it!</Button>
            </>
          ) : (
            <Step1 />
          )
        }
      </Container>
    </Flex>
  )
}

interface StepProps {
  accessToken: string
  userId: string
}

const Step1: React.FC = () => {
  const toast = useToast()
  const { values, handleSubmit, handleChange, setValues } = useFormik({
    initialValues: {
      firstName: '',
      lastName: '', 
    },
    onSubmit: (data) => {
      console.log(data)
      // if (accessToken) {
      //   update({ accessToken, data })
      //     .then(user => toast({
      //       title: 'Settings saved',
      //       status: 'success',
      //       duration: 9000
      //     }))
      //     .catch(err => toast({
      //       title: 'Eeks! Something went wrong updating your settings',
      //       description: 'We have logged this event and our team is be looking into it. Thanks for your understanding.',
      //       status: 'warning',
      //       duration: 9000
      //     }))
      // }
    }
  })

  // useEffect(() => {
  //   setValues({
  //     ...values,
  //     firstName,
  //     lastName
  //   })
  // }, [firstName, lastName])


  return (
    <SimpleGrid columns={{ base: 1, lg: 2}}>
      <Box>
        <Heading size='lg' pb={6}>What should we call you?</Heading>
        <Text fontSize='xl' pb={6}></Text>
      </Box>
      <Box
        bg={useColorModeValue('white', 'gray.900')}
        boxShadow={'2xl'}
        rounded={'md'}
        p={6}      
        overflow={'hidden'}
        borderWidth='1px' 
        borderRadius='lg'
      >
        <Stack spacing={4}>
          <Box>
            <FormControl>
              <FormLabel>What is your first name?</FormLabel>
              <Input 
                // placeholder="coming soon!"
                id='firstName'
                name='firstName'
                onChange={handleChange}
                value={values.firstName || ''}/>
            </FormControl>
          </Box>          
          <Box>
            <FormControl>
              <FormLabel>What is your first name?</FormLabel>
              <Input 
                // placeholder="coming soon!"
                id='lastName'
                name='lastName'
                onChange={handleChange}
                value={values.lastName || ''}/>
            </FormControl>
          </Box>          
        </Stack> 
      </Box>
    </SimpleGrid>
  )
}


