import { useEffect } from 'react'
import { SimpleGrid, Box, Heading, HStack, Text, FormControl, FormLabel, Input, InputLeftElement, Stack, useColorModeValue, Flex, Spacer, Select, Button, Skeleton, Spinner, InputGroup, useToast} from '@chakra-ui/react'
import { StateList } from '../utils'
import { update } from '../api/fi/update'
import { FiSettingsProps } from '../routes/dashboard'
import { useFormik } from 'formik'

export const FiAddressSettings: React.FC<FiSettingsProps> = ({ isLoading, accessToken, fi: { rssdId, streetAddress = '', city = '', state = '', postalCode = '' } }) => {
  const toast = useToast()
  const { values, handleSubmit, handleChange, setValues } = useFormik({
    initialValues: {
      streetAddress: '',
      city: '',
      state: '',
      postalCode: ''
    },
    onSubmit: (data) => {
      if (accessToken && rssdId) {
        update({ accessToken, rssdId, data })
          .then(fi => toast({
            title: 'Address saved',
            status: 'success',
            duration: 9000
          }))
          .catch(err => toast({
            title: 'Eeks! Something went wrong updating your address',
            description: 'We have logged this event and our team is be looking into it. Thanks for your understanding.',
            status: 'warning',
            duration: 9000
          }))
      }
    }
  })

  useEffect(() => {
    setValues({
      streetAddress,
      city,
      state,
      postalCode
    })
  }, [streetAddress, city, state, postalCode])

  return (
    <SimpleGrid columns={{sm: 1, lg: 2}}>
      <Box>
        <Heading size='lg' pb={6}>Corporate address:</Heading>
        <Text size='lg' pb={6}>Where is the FI&apos;s headquarters located?</Text>
      </Box>
      <Box
        bg={useColorModeValue('white', 'gray.900')}
        boxShadow={'2xl'}
        rounded={'md'}
        p={6}      
        overflow={'hidden'}
        borderWidth='1px' 
        borderRadius='lg'
      >
        <form onSubmit={handleSubmit}>
          <Stack spacing={4}>
            <Box>
              <FormControl id="formalterm" isRequired>
                <FormLabel>Street address:</FormLabel>
                <Skeleton isLoaded={!isLoading}>
                  <Input 
                    type='text'
                    id='streetAddress'
                    name='streetAddress'
                    onChange={handleChange}
                    value={values.streetAddress || ''}/>
                </Skeleton>
              </FormControl>
            </Box>            
            <HStack>
              <Box w={'60%'}>
                <FormControl id="city" isRequired>
                  <FormLabel>City:</FormLabel>
                  <Skeleton isLoaded={!isLoading}>
                    <Input 
                      type='text'
                      id='city'
                      name='city'
                      onChange={handleChange}
                      value={values.city || ''}/>
                  </Skeleton>                
                </FormControl>
              </Box>
              <Box w={'40%'}>
                <FormControl id="state" isRequired>
                  <FormLabel>State:</FormLabel>
                  <Skeleton isLoaded={!isLoading}>
                    <Select 
                      id='state' 
                      placeholder='Select state'
                      name='state'
                      onChange={handleChange}
                      value={values.state || ''}>
                      {StateList.map(({ abbreviation }) => <option key={abbreviation} value={abbreviation}>{abbreviation}</option>)}
                    </Select>
                  </Skeleton>
                </FormControl>
              </Box>              
            </HStack>
            <Box>
              <FormControl id="postalCode" isRequired>
                <FormLabel>Zip:</FormLabel>
                <Skeleton isLoaded={!isLoading}>
                  <Input 
                    type='text'
                    id='postalCode'
                    name='postalCode' 
                    onChange={handleChange}
                    value={values.postalCode || ''}/>
                </Skeleton>
              </FormControl>
            </Box>
            <Flex>
              <Spacer />
              <Button
                mt={4}
                colorScheme='teal'
                type='submit'
                px={6}
              >
                Save
              </Button>
            </Flex> 
          </Stack>
        </form>
      </Box>
    </SimpleGrid>
  )
}