import { Box, Button, ButtonGroup, Container, Flex, Heading, Spacer, Text, VStack, useDisclosure, useToast } from '@chakra-ui/react'
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter'
import markup from 'react-syntax-highlighter/dist/esm/languages/prism/markup'
import okaidia from 'react-syntax-highlighter/dist/esm/styles/prism/okaidia'
import { useAuth0 } from '@auth0/auth0-react'
import { EmailSnippetModal } from '../../components'
SyntaxHighlighter.registerLanguage('markup', markup)

export const Snippets:React.FC = () => {

  return (
    <Container maxW={'7xl'} py={12}>
      <VStack>
        <Heading 
          fontSize={'4xl'}
          textAlign={'center'}
        >
          Generate an Autobooks landing page on example.com in 30 minutes
        </Heading>
        <Spacer />
        <Heading 
          fontSize={'2xl'}
          textAlign={'center'}
        >Start promoting Autobooks to your customers today</Heading>
        <Box w='6xl' p='6'>
          <Text fontSize='xl' pb={2}>Adding these Autobooks sales tools to your web site is as easy as copying and pasting.</Text>
          <Text fontSize='xl' pb={2}>If you know how to embed a youtube video or other custom content on your site, you can get started embedding these tools on your site.</Text>
          <Text fontSize='xl' pb={2}>Click to copy and paste the code snippet to add to your Autobooks landing page. </Text>
          <Text fontSize='xl' pb={2}>If you don&apos;t have access to your site, click to email it to a developer on your team.</Text>
        </Box>      
        <DefaultLandingPage />
        <DemoWidget />
        <Box w='6xl'>
          <Text fontSize='xl' pb={2}>Enhance the channels your team is already running with our proven sales tools </Text>
          <Text fontSize='xl' pb={2}>Remove a snippet at any time as easily as deleting 2 lines of code.</Text>          
        </Box>
        <Box w='6xl' textAlign={'center'} >
          <Text as='i'>All snippets are compliant with ADA web accessibility regulations.</Text>
        </Box>
      </VStack>
    </Container>
  )
}

const DefaultLandingPage = () => {

  const { onOpen } = useDisclosure()
  const toast = useToast()
  const { user, isLoading } = useAuth0()
  const namespace  = process.env.REACT_APP_AUTH0_NAMESPACE

  return (
    <Box maxW="7xl" w='100%' pt={5} px={{ base: 2, sm: 12, md: 17 }}>
      <Heading
        textAlign={'center'}
        fontSize={'xl'}
        py={10}
        fontWeight={'bold'}>
        Send an invoice landing page
      </Heading>
      <Box w='100%'>
        <SyntaxHighlighter language='markup' style={okaidia} showLineNumbers={true}>
          {user ? codeSnippet({ rssdId: user[`${namespace}/rssd_id`], natId: 17 }) : 'Loading...'}
        </SyntaxHighlighter>
      </Box>      
      <Flex>
        <Spacer />
        <ButtonGroup size='sm' isAttached variant='outline'>
          <Button
            onClick={() => toast({
              title: 'Copied to clipboard',
              status: 'success',
              duration: 9000,
              isClosable: false,
            })}
          >
            Click to copy
          </Button>
          <EmailSnippetModal />
        </ButtonGroup>
      </Flex>
    </Box>
  )
}

const DemoWidget = () => {

  const { onOpen } = useDisclosure()
  const toast = useToast()
  const { user, isLoading } = useAuth0()
  const namespace  = process.env.REACT_APP_AUTH0_NAMESPACE

  return (
    <Box maxW="7xl" w='100%' pt={5} px={{ base: 2, sm: 12, md: 17 }}>
      <Heading
        textAlign={'center'}
        fontSize={'xl'}
        py={10}
        fontWeight={'bold'}>
        Schedule a demo widget
      </Heading>
      <SyntaxHighlighter language='markup' style={okaidia} showLineNumbers={true}>
        {user ? codeSnippet({ rssdId: user[`${namespace}/rssd_id`], natId: 17, snippet: 'schedule-a-demo' }) : 'Loading...'}
      </SyntaxHighlighter>
      <Flex>
        <Spacer />
        <ButtonGroup size='sm' isAttached variant='outline'>
          <Button
            onClick={() => toast({
              title: 'Copied to clipboard',
              status: 'success',
              duration: 9000,
              isClosable: false,
            })}
          >
            Click to copy
          </Button>
          <EmailSnippetModal />
        </ButtonGroup>
      </Flex>
    </Box>
  )
}

interface codeSnippetArguments {
  rssdId: number;
  natId?: number;
  snippet?: string;
}

const codeSnippet = ({ rssdId, natId, snippet  }: codeSnippetArguments) => {
  if (snippet === 'schedule-a-demo') {
    return `<div id="ab-parent-container" data-nat-id="${natId || ''}" data-rssd-id="${rssdId}" data-lp="schedule-a-demo"></div>
<script src="https://info.autobooks.co/hubfs/4147193/ab-iframe.js"></script>`    
  }
  return `<div id="ab-parent-container" data-nat-id="${natId || ''}" data-rssd-id="${rssdId}"></div>
<script src="https://info.autobooks.co/hubfs/4147193/ab-iframe.js"></script>`  
}