import { Badge, Box, Button, Container, Flex, FormControl, FormLabel, FormHelperText, Heading, Input, Table, TableCaption, Textarea, Thead, Tr, Th, Tbody, Td, Select, Spacer, Skeleton, SimpleGrid, Stack, useColorModeValue,  } from '@chakra-ui/react'
import { useAuth0 } from '@auth0/auth0-react'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { create, read, CreateTicketArgs } from '../../api/tickets'
const { REACT_APP_SMB_UTILITY_API } = process.env

interface State {
  isLoading: boolean
  tickets: Ticket[]
}
interface Ticket {
  id: string
  createdAt: string
  updatedAt: string
  properties: {
    subject?: string
    createdate?: string 
    hs_pipeline_stage: '13880205' | '13880206' | '13880207' | '13880208' 
  }
}

const PIPELINE_STAGES = {
  '13880205': {
    'label': 'New',
    'color': 'purple'
  },
  '13880206': {
    'label': 'Needs attention',
    'color': 'orange'
  },
  '13880207': {
    'label': 'Pending',
    'color': 'blue'
  },
  '13880208': {
    'label': 'Closed',
    'color': 'green'
  }
}

const FIS_PIPELINE = '4124936'
const BANNO_PIPELINE = '4185874'


export const Support = () => {
  const [state, setState] = useState<State>({ isLoading: true, tickets: []})
  const { isAuthenticated, getAccessTokenSilently } = useAuth0()
  const navigate = useNavigate()  

  useMemo(() => {         
    const readAndSetTickets = async () => {
      const token = await getAccessTokenSilently()
      const { tickets } = await read({ token })
      setState({
        isLoading: false,
        tickets
      })      
    }   
    if( isAuthenticated) {
      readAndSetTickets()
    }
  }, [isAuthenticated])

  return (
    <Container maxW={'6xl'} py={12}>
      <Heading pb={12}>Support center:</Heading>
      <Stack spacing={12}>
        <SubmitTicket />
        <Table variant='simple'>
          <TableCaption>Current support tickets</TableCaption>
          <Thead>
            <Tr>
              <Th>Status</Th>
              <Th>Ticket</Th>
              <Th>Created</Th>
              <Th>Last updated</Th>
              <Th>&nbsp;</Th>
            </Tr>
          </Thead>
          <Tbody>
            {state.isLoading && [1, 2, 3].map(i => (
              <Tr key={i}>
                <Td>
                  <Skeleton>
                    <Badge colorScheme='purple'>&nbsp;</Badge>
                  </Skeleton>
                </Td>
                <Td><Skeleton>&nbsp;</Skeleton></Td>
                <Td><Skeleton>&nbsp;</Skeleton></Td>
                <Td><Skeleton>&nbsp;</Skeleton></Td>
                <Td isNumeric><Skeleton>&nbsp;</Skeleton></Td>
              </Tr>
            ))}
            {!state.isLoading && state.tickets.map(({ id, createdAt, updatedAt, properties }) => (
              <Tr key={id}>
                <Td><Badge colorScheme={PIPELINE_STAGES[properties.hs_pipeline_stage].color}>{PIPELINE_STAGES[properties.hs_pipeline_stage].label}</Badge></Td>
                <Td>{ properties.subject }</Td>
                <Td>{ new Date(createdAt).toLocaleDateString()}</Td>
                <Td>{ new Date(updatedAt).toLocaleDateString()}</Td>
                <Td isNumeric><Button onClick={() => navigate(id)}>View details</Button></Td>
              </Tr>
            ))}
          </Tbody>      
        </Table>
      </Stack>
    </Container>    
  )
}

const SubmitTicket = () => {
  const [isLoading, setIsLoading] = useState(false)
  const { getAccessTokenSilently } = useAuth0()
  const { values, handleSubmit, handleChange, setValues } = useFormik({
    initialValues: {
      subject: '',
      content: '',
      category: 'GENERAL_INQUIRY'
    },
    onSubmit: async () => {
      setIsLoading(true)
      const token = await getAccessTokenSilently()
      create({
        token,
        properties: values
      })
      .then(ticket => {
        setValues({
          subject: '',
          content: '',
          category: 'GENERAL_INQUIRY'
        })
        // toast 
        console.log(ticket);
        setIsLoading(false)
      })
      .catch(e => {
        setIsLoading(false)
      })
    }
  })

  return (
    <SimpleGrid columns={{ base: 1, md: 2}}>
      <Box>
        <Heading>Create a new ticket:</Heading>
      </Box>
      <Box
        bg={useColorModeValue('white', 'gray.900')}
        boxShadow={'2xl'}
        rounded={'md'}
        p={6}      
        overflow={'hidden'}
        borderWidth='1px' 
        borderRadius='lg'
      >
        <form  onSubmit={handleSubmit}>
          <Stack spacing={4}>
            <Box>
              <FormControl id='subject' isRequired>
                <FormLabel>Subject:</FormLabel>
                <Skeleton isLoaded={!isLoading}>
                  <Input 
                    type='text' 
                    id='subject'
                    name='subject'
                    onChange={handleChange}
                    value={values.subject || ''}/>
                </Skeleton>
              </FormControl>
            </Box>
            <Box>
              <FormControl id='content' isRequired>
                <FormLabel>Description:</FormLabel>
                <Skeleton isLoaded={!isLoading}>
                  <Textarea 
                    id='content'
                    name='content'
                    onChange={handleChange}
                    value={values.content || ''}/>
                </Skeleton>
              </FormControl>
            </Box>
            <Box>
              <FormControl id='category' isRequired>
                <FormLabel>Category:</FormLabel>
                <Skeleton isLoaded={!isLoading}>
                  <Select
                    id='category'
                    name='category'
                    onChange={handleChange}
                    value={values.category || 'GENERAL_INQUIRY'}
                  >
                    <option value='PRODUCT_ISSUE'>Product issue</option>
                    <option value='BILLING_ISSUE'>Billing issue</option>
                    <option value='FEATURE_REQUEST'>Feature request</option>
                    <option value='GENERAL_INQUIRY'>General inquiry</option>
                  </Select>
                </Skeleton>
              </FormControl>
            </Box>
            <Flex>
              <Spacer />
              <Button
                mt={4}
                colorScheme='teal'
                type='submit'
                px={6}
                isLoading={isLoading}
              >
                Save
              </Button>
            </Flex> 
          </Stack>
        </form>
      </Box>
    </SimpleGrid>
  )
}