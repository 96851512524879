import {
  Badge,
  Button,
  Center,
  Flex,
  Heading,
  Image,
  Link,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { useAuth0 } from '@auth0/auth0-react'
import React, { useEffect, useState } from 'react'
  
export const Profile: React.FC = () => {

  const { user, getAccessTokenSilently } = useAuth0()
  const [userMetadata, setUserMetadata] = useState({
    user_metadata: null,
    app_metadata: null
  })

  useEffect(() => {
    const getUserMetadata = async () => {
      const domain = 'dev-4o1uc7zw.us.auth0.com'
    
      try {
        const accessToken = await getAccessTokenSilently()
    
        const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user && user.sub}`
    
        const metadataResponse = await fetch(userDetailsByIdUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
    
        const response = await metadataResponse.json()
          

        const { user_metadata, app_metadata } = response
    
        setUserMetadata({
          user_metadata,
          app_metadata
        })
      } catch (e : any) {
        console.error(e && e.message)
      }
    }
    
    getUserMetadata()
  }, [getAccessTokenSilently, user])
    

  return (
    <Center py={6}>
      <Stack
        borderWidth="1px"
        borderRadius="lg"
        w={{ sm: '100%', md: '540px' }}
        height={{ sm: '476px', md: '20rem' }}
        direction={{ base: 'column', md: 'row' }}
        bg='white'
        boxShadow={'2xl'}
        padding={4}>
        <Flex flex={1} bg="blue.200">
          <Image
            objectFit="cover"
            boxSize="100%"
            src={user?.picture}
          />
        </Flex>
        <Stack
          flex={1}
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          p={1}
          pt={2}>
          <Heading fontSize={'2xl'} fontFamily={'body'}>
            {user?.name}
          </Heading>
          <Text fontWeight={600} color={'gray.500'} size="sm" mb={4}>
            {user?.email}
          </Text>
          <Text
            textAlign={'center'}
            color='gray.700'
            px={3}>
              Actress, musician, songwriter and artist. PM for work inquires or
            <Link href={'#'} color={'blue.400'}>
                #tag
            </Link>
              me in your posts
          </Text>
          <Stack align={'center'} justify={'center'} direction={'row'} mt={6}>
            <Badge
              px={2}
              py={1}
              bg='gray.50'
              fontWeight={'400'}>
                #art
            </Badge>
            <Badge
              px={2}
              py={1}
              bg='gray.50'
              fontWeight={'400'}>
                #photography
            </Badge>
            <Badge
              px={2}
              py={1}
              bg='gray.50'
              fontWeight={'400'}>
                #music
            </Badge>
          </Stack>
          <div>
            {userMetadata ? (
              <pre>{JSON.stringify(userMetadata, null, 2)}</pre>
            ) : (
              'No user metadata defined'
            )}
          </div>
  
          <Stack
            width={'100%'}
            mt={'2rem'}
            direction={'row'}
            padding={2}
            justifyContent={'space-between'}
            alignItems={'center'}>
            <Button
              flex={1}
              fontSize={'sm'}
              rounded={'full'}
              _focus={{
                bg: 'gray.200',
              }}>
                Message
            </Button>
            <Button
              flex={1}
              fontSize={'sm'}
              rounded={'full'}
              bg={'blue.400'}
              color={'white'}
              boxShadow={
                '0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)'
              }
              _hover={{
                bg: 'blue.500',
              }}
              _focus={{
                bg: 'blue.500',
              }}>
                Follow
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Center>
  )
}