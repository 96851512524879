import { Avatar, AvatarBadge, Flex, Heading, Icon, Spacer, Stack, Text, useColorModeValue, useBreakpointValue } from "@chakra-ui/react"
import { useState } from "react"
import { FiCalendar, FiGift, FiMail, FiPieChart, FiChevronRight } from 'react-icons/fi'


export interface NotificationCardProps {
  category?: 'feature' | 'webinar' | 'event' | 'announcement' | 'report'
  title: string
  description?: string
  isRead?: boolean
}

export const NotificationCard: React.FC<NotificationCardProps> = ({ category, title, description, isRead }) => {

  const [isReadLocal, setIsReadLocal] = useState(isRead)
  const size = useBreakpointValue({ base: 'sm', md: 'lg'})

  const CATEGORIES = {
    'feature' : {
      'icon': <FiGift />,
      'color': 'cyan.500'
    },
    'webinar': {
      'icon': <FiCalendar />,
      'color': 'orange.500'      
    },
    'event': {
      'icon': <FiCalendar />,
      'color': 'red.500'
    },
    'announcement': {
      'icon': <FiMail />,
      'color': 'blue.500'
    },
    'report': {
      'icon': <FiPieChart />,
      'color': 'pink.500'
    },
    'default': {
      'icon': <FiGift />,
      'color': 'blue.500'
    }
  }

  console.log(isReadLocal)
  
  return(
    <Flex
      bg={useColorModeValue('white', 'gray.900')}
      boxShadow={'2xl'}
      rounded={'md'}
      p={6}      
      overflow={'hidden'}
      borderWidth='1px' 
      borderRadius='lg'
      w={'100%'}
      onClick={() => setIsReadLocal(true)}
      cursor='pointer'
      _hover={{ bg: useColorModeValue('gray.100', 'gray.800')}}
      >
        <Avatar bg={!isReadLocal ? CATEGORIES[category || 'default'].color : 'grey'} size={size} icon={CATEGORIES[category || 'default'].icon}>
          {!isReadLocal ? <AvatarBadge bg='tomato' boxSize='1.25em' /> : null}
        </Avatar>
        <Stack direction={'column'} pl={6}>
          <Heading size={size}>
            {title}
          </Heading>
          <Text noOfLines={1}>{description}</Text>
        </Stack>
        <Spacer />
        <Flex direction={'column'} pl={6}>
          <Spacer />
          <Icon boxSize={'2em'} as={FiChevronRight} />
          <Spacer />
        </Flex>        
    </Flex>
  )
}