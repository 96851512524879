import { Outlet } from 'react-router-dom'
import { Container, VStack, Heading, Spacer, SimpleGrid } from '@chakra-ui/react'
import { ProductCard } from '../../components'

const PRODUCTS = [
  
  {
    title: 'Do you know how much business Square is stealing from you?',
    description: 'The Signal Campaign is designed to reach out to your customer before they switch to a payment app competitor.',
    slug: 'competitor-displacement-product',
    image: 'https://images.unsplash.com/photo-1634547307446-17a86765474e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3270&q=80',
    tags: ['intelligent leads'],
    cta: 'Learn more'
  },
  {
    title: 'The secret weapon to getting new accounts funded quickly',
    description: 'You need to get new accounts funded. Your customers need help getting paid.',
    slug: 'new-account-opening',
    image: 'https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3270&q=80',
    tags: ['intelligent leads'],
    cta: 'Learn more'
  }

]

export const Products: React.FC = () => {
  return (
    <Container maxW={'6xl'} py={12}>
      <VStack align='stretch' spacing={6}>
        <Heading>Sales tools:</Heading>
        <Spacer />
        <SimpleGrid columns={2} spacing={10}>
          {PRODUCTS.map((product, i) => (
            <ProductCard 
              key={product.slug || i}
              title={product.title}
              description={product.description}
              slug={product.slug}
              image={product.image}
              tags={product.tags}
              cta={product.cta}
            />
          ))}
        </SimpleGrid>
      </VStack>
    </Container>
  )
}