import { IFinancialInstitution } from '.'
const { REACT_APP_SMB_UTILITY_API } = process.env

export interface UpdateFinancialInstitutionArguments {
  accessToken: string
  rssdId: number
  data: IFinancialInstitution
}

export const update = async ({ accessToken, rssdId, data }: UpdateFinancialInstitutionArguments) : Promise<IFinancialInstitution> => {
  const fiResponse = await fetch(`${REACT_APP_SMB_UTILITY_API}/v1/fi/${rssdId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    method: 'PUT',
    body: JSON.stringify(data)
  });
  return await fiResponse.json()
}