import {
  Button,  
  Input,
  InputGroup,
  InputRightAddon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  useDisclosure
} from '@chakra-ui/react'

export const EmailSnippetModal = () => {
  const { isOpen, onClose, onOpen } = useDisclosure()

  return (
    <>
      <Button onClick={onOpen}>
        Send to IT 
      </Button>
      <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Email snippet</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb='1rem'>
              Enter your IT person&apos;s address and we will shoot them instructions on how to embed the snippet on your site. 
            </Text>
            <InputGroup size='sm'>
              <Input placeholder='developer' />
              <InputRightAddon>@bankdomain.com</InputRightAddon>
            </InputGroup>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue'>Send email</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}