import { useState } from 'react'
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box, 
  Button,
  Heading,
  Skeleton,
  Icon,
  Table,
  Text, 
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  useToast,
} from '@chakra-ui/react'
import {  FiStar, FiAward } from 'react-icons/fi'
import { Link } from './'
import { useAuth0 } from '@auth0/auth0-react'
import { useEffect } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'

const { REACT_APP_AUTH0_DOMAIN, REACT_APP_SMB_UTILITY_API } = process.env


const RECENT_REPORTS = [
  { report: 'Autobooks Stats',           year: '2022', month: 'February', status: 'pending', filename: 'stats.csv', url: null, isPremium: false },
  { report: 'Autobooks SMBs',           year: '2022', month: 'February',  status: 'pending', filename: 'smbs.csv', url: null, isPremium: false },
  { report: 'Competitive Wallet Share',  year: '2022', month: 'February', status: 'pending', filename: 'competitive_wallet_share.csv', url: '#', isPremium: true }  
]

export const RecentReportsTable = () => {
  const toast = useToast()
  const navigate = useNavigate()
  const [state, setState] = useState({ isLoading: true, reports: [], isAuthorized: true })
  const { isLoading, getAccessTokenSilently, getIdTokenClaims } = useAuth0()

  useEffect(() => {
    const getReports = async () => {
      try {

        const accessToken = await getAccessTokenSilently()
        
        const reportResponse = await fetch(`${REACT_APP_SMB_UTILITY_API}/v1/reports`, {
          headers: {
            'authorization': `Bearer ${accessToken}`          },
          method: 'POST'
        });

        if (reportResponse.status === 401) {
          setState({
            ...state,
            isLoading: false,
            isAuthorized: false
          })
        } else {
          const { reports } = await reportResponse.json()
        
          setState({
            ...state,
            isLoading: false,
            reports
          })
        }
        

      } catch (e) {
        console.log(e)
      }      
    }    
    getReports()
  },[getAccessTokenSilently, isLoading])

  const openInNewTab = async ({ id }: { id: number; }) => {

    const accessToken = await getAccessTokenSilently();

    const newWindow = window.open(`${REACT_APP_SMB_UTILITY_API}/v1/reports/${id}?token=${accessToken}`, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null

  }

  return (
    <Box maxW="7xl" mx={'auto'} pt={5} px={{ base: 2, sm: 12, md: 17 }}>
      <Heading
        textAlign={'center'}
        fontSize={'4xl'}
        py={10}
        fontWeight={'bold'}>
        Recent Reports
      </Heading>
      {!state.isLoading && !state.isAuthorized ? <NotAuthorized /> : null}
      <Table variant='simple'>
        <TableCaption>Access all of your reports from the <Link to='/dashboard/reports'>reporting tab</Link></TableCaption>
        <Thead>
          <Tr>
            <Th>VIP</Th>
            <Th>Hit goal</Th>
            <Th>Report</Th>
            <Th>Month</Th>
            <Th>Year</Th>
            <Th>Status</Th>            
            <Th isNumeric>Download</Th>
          </Tr>
        </Thead>
        <Tbody>
          {state.isLoading ? <LoadingTable /> : null}          
          {!state.isLoading && state.reports.map(({id, name, month, year, status,  isPremium, hitGoal, fileName }) => (
            <Tr key={id}>
              <Td>{isPremium
                ? <Icon
                  mr="4"
                  fontSize="16"
                  _groupHover={{
                    color: 'white',
                  }}
                  as={FiStar}
                />
                : null
              }</Td>
              <Td>
                <Icon
                  mr="4"
                  fontSize="16"
                  _groupHover={{
                    color: 'white',
                  }}
                  as={FiAward}
                />
              </Td>
              <Td>{name}</Td>
              <Td>{month}</Td>
              <Td>{year}</Td>
              <Td>{fileName ? 'Ready' : 'Pending'}</Td>
              <Td isNumeric>
                <Button 
                    size='sm' 
                    variant='outline' 
                    colorScheme='purple' 
                    disabled={fileName ? false : true} 
                    onClick={() => openInNewTab({id})}
                  >
                    {fileName ? 'Download' : 'Pending...'}
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>        
      </Table>
    </Box>    
  )
}

const NotAuthorized = () => (
  <Alert status='error' my={6}>
    <AlertIcon />
    <AlertTitle mr={2}>Unauthorized</AlertTitle>
    <AlertDescription>You aren&apos;t currently authorized to access these resources. If you feel like this is not right, you may contact us.</AlertDescription>
  </Alert>
)

const LoadingTable = () => {
  const LoadingCell = () => <Td><Skeleton>Loading...</Skeleton></Td>
  return (
    <>
      {[1, 2, 3].map(row => (
        <Tr key={row}>
          <LoadingCell />
          <LoadingCell />
          <LoadingCell />
          <LoadingCell />
          <LoadingCell />
          <LoadingCell />
        </Tr>
      ))}
    </>
  )
}
