import { Container, Heading, SimpleGrid, Spacer, VStack } from '@chakra-ui/react'
import { TrainingCard, TrainingCardProps } from '../../components'

const COURSES : TrainingCardProps[] = [
  { 
    title: 'The Basics', 
    description: 'Learn about our Invoicing and Payment Features and how they benefit customers with small businesses.', 
    authors: [
      { name: 'Kim Reher', slug: 'kim-reher', avatar: '', title: 'Head of Training' },
      { name: 'Ashley DiFonzo', slug: 'ashley-difonzo', avatar: ''}
    ], 
    href: 'https://learn.autobooks.co/banno-training-page-signup-2021',
    image: 'https://images.unsplash.com/photo-1621020512837-280d1c1ccc7b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3270&q=80',
    level: 'getting started',
    length: 'short',
    tags: ['live webinar'],
    cta: 'Register'
  },
  { 
    title: 'Driving Enrollments', 
    description: 'After attending The Basics, join this session to learn how to target the right customer and how to drive enrollments.', 
    authors: [
      { name: 'Kim Reher', slug: 'kim-reher', avatar: '', title: 'Head of Training' },
      { name: 'Ashley DiFonzo', slug: 'ashley-difonzo', avatar: ''}
    ], 
    href: 'https://learn.autobooks.co/bannosales-training-page-signup-2022?hsCtaTracking=7b1817fb-c058-452b-a4b7-90b6b2a5c658%7Cc01598f0-c562-482c-ab2c-7a2936fb2bd1',
    image: 'https://images.unsplash.com/photo-1552581234-26160f608093?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3270&q=80',
    level: 'intermediate',
    length: 'short',
    tags: ['live webinar'],
    cta: 'Register'
  }
]


export const Training: React.FC = () => {
  return(
    <Container maxW={'6xl'} py={12}>
      <VStack align='stretch' spacing={6}>
        <Heading>Training courses:</Heading>
        <Spacer />
        <SimpleGrid columns={2} spacing={10}>
          {COURSES.map((course, i) => (
            <TrainingCard 
              key={course.slug || i}
              title={course.title}
              description={course.description}
              authors={course.authors}
              slug={course.slug}
              href={course.href}
              image={course.image}
              level={course.level}
              tags={course.tags}
              cta={course.cta}
            />
          ))}
        </SimpleGrid>
      </VStack>
    </Container>
  )
}