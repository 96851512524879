import { ChakraProvider } from '@chakra-ui/react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Auth0ProviderWithHistory } from './utils';
import { AcceptTerms, Authorization, AuthLayout, Reports, DashboardHome, Home, Products, CompetitiveDisplacement, Splash, VerifyEmail, Welcome, Snippets, Signup, Referrals, Profile, Logout, Training, Notifications, Settings, SmallBusinessApply, NewAccountOpening, Support } from './routes'
import { Protect } from './components'
import { theme } from './utils'
import { SuperHeros } from './routes/public/SuperHeros';

function App() {

  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <Auth0ProviderWithHistory>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/logout"element={<Logout />} />            

            <Route path="onboarding">
        
              {/* Step 1 */}
              <Route index element={<Welcome />} />

              <Route path='verify-email' element={<VerifyEmail />}/>
              <Route path='accept-terms' element={<AcceptTerms />}/>



            </Route>
            <Route path="dashboard" element={
              <Protect status='verified' redirectPath='/onboarding'>
                <AuthLayout />
              </Protect>
            }>
              <Route index element={<DashboardHome />} />
              <Route path='splash' element={<Splash />}/>
              <Route path='reports' element={<Reports />} />
              <Route path='products' element={<Products />} />
              <Route path='products/competitor-displacement-product' element={<CompetitiveDisplacement />} />
              <Route path='products/small-business-apply' element={<SmallBusinessApply />} />
              <Route path='products/new-account-opening' element={<NewAccountOpening />} /> 
              <Route path='support' element={<Support />} >
                <Route path=':id' element={<Support />} />
              </Route>
              <Route path='snippets' element={<Snippets />} />
              <Route path='referrals' element={<Referrals />} />
              <Route path='training' element={<Training />}>
                <Route path=":slug" element={<Training />} />
              </Route>

              {/* Right hand menu */}
              <Route path='profile' element={<Profile />} />
              <Route path='settings' element={<Settings />} />
              <Route path='superheros' element={<SuperHeros />} />
              <Route path='notifications' element={<Notifications />} />
            </Route>

            {/* {
              Public routes for testing
            } */}
            <Route path="/p/authorizing" element={<Authorization />}/>
          </Routes>
        </Auth0ProviderWithHistory>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
