import { SimpleGrid, Box, Heading, Text, FormControl, FormLabel, Input, Stack, FormHelperText, useColorModeValue, Flex, Spacer, Button, Skeleton, useToast } from '@chakra-ui/react'
import { useFormik } from 'formik'
import { useEffect } from 'react'
import { FiSettingsProps } from '../routes/dashboard'
import { update } from '../api/fi/update'


export const FiBasicSettings: React.FC<FiSettingsProps> = ({ isLoading, accessToken, fi: { rssdId, formalTerm = '', commonTerm = '', internalTerm = '' }}) => {
  const toast = useToast()
  
  const { values, handleSubmit, handleChange, setValues } = useFormik({
    initialValues: {
      formalTerm: '',
      commonTerm: '',
      internalTerm: ''
    },
    onSubmit: (data) => {
      if (accessToken && rssdId) {
        update({ accessToken, rssdId, data })
          .then(fi => toast({
            title: 'Settings saved',
            status: 'success',
            duration: 9000
          }))
          .catch(err => toast({
            title: 'Eeks! Something went wrong updating your settings',
            description: 'We have logged this event and our team is be looking into it. Thanks for your understanding.',
            status: 'warning',
            duration: 9000
          }))
      }
    }
  })

  useEffect(() => {
    setValues({
      formalTerm,
      commonTerm,
      internalTerm
    })
  }, [formalTerm, commonTerm, internalTerm])

  return (
    <SimpleGrid columns={{sm: 1, lg: 2}} >
      <Box>
        <Heading size='lg' pb={6}>Basic Details:</Heading>
        <Text size='lg' pb={6}></Text>
      </Box>
      <Box
        bg={useColorModeValue('white', 'gray.900')}
        boxShadow={'2xl'}
        rounded={'md'}
        p={6}      
        overflow={'hidden'}
        borderWidth='1px' 
        borderRadius='lg'
      >
        <form  onSubmit={handleSubmit}>
          <Stack spacing={4}>
            <Box>
              <FormControl id="formalterm" isRequired>
                <FormLabel>FI formal term:</FormLabel>
                <Skeleton isLoaded={!isLoading}>
                  <Input 
                    type='text' 
                    id='formalTerm'
                    name='formalTerm'
                    onChange={handleChange}
                    value={values.formalTerm || ''}/>
                </Skeleton>                
                <FormHelperText>What name does compliance use for the bank?</FormHelperText>
              </FormControl>
            </Box>
            <Box>
              <FormControl id="commonterm" isRequired>
                <FormLabel>FI common term:</FormLabel>
                <Skeleton isLoaded={!isLoading}>
                  <Input 
                    type='text'
                    id='commonTerm'
                    name='commonTerm'
                    onChange={handleChange}
                    value={values.commonTerm || ''}/>
                </Skeleton>
                <FormHelperText>What name does your customers use for the FI?</FormHelperText>
              </FormControl>
            </Box>
            <Box>
              <FormControl id="internal" isRequired>
                <FormLabel>Bank internal term:</FormLabel>
                <Skeleton isLoaded={!isLoading}>
                  <Input 
                    type='text'
                    name='internalTerm'
                    id='internalTerm'
                    onChange={handleChange}
                    value={values.internalTerm || ''}/>
                </Skeleton>
                <FormHelperText>What do you call the bank internally?</FormHelperText>
              </FormControl>
            </Box>
            <Flex>
              <Spacer />
              <Button
                mt={4}
                colorScheme='teal'
                type='submit'
                px={6}
              >
                Save
              </Button>
            </Flex> 
          </Stack>
        </form>
      </Box>
    </SimpleGrid>
  )
}