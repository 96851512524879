import { useState } from 'react'
import {
    Container,
    SimpleGrid,
    Image,
    Flex,
    Heading,
    Text,
    Stack,
    StackDivider,
    Icon,
    useColorModeValue,
    AspectRatio,
    Button
  } from '@chakra-ui/react'
  import { PopupModal } from 'react-calendly'
  
  export const NewAccountOpening = () => {

    const [isPopupOpen, setIsPopupOpen] = useState(false)

    return (
      <Container maxW={'6xl'} py={12}>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
          <Stack spacing={4}>
            <Text
              textTransform={'uppercase'}
              color={'blue.400'}
              fontWeight={600}
              fontSize={'sm'}
              bg={useColorModeValue('blue.50', 'blue.900')}
              p={2}
              alignSelf={'flex-start'}
              rounded={'md'}>
              Our Story
            </Text>
            <Heading>The Secret Weapon to Getting New Accounts Funded Quickly</Heading>
            <Text color={'gray.500'} fontSize={'lg'}>
              You need to get new accounts funded. Your customers need help getting paid. 
            </Text>
            <Text color={'gray.500'} fontSize={'lg'}>
              The New Account Opening Campaign will help your customers adopt Autobooks so both problems get solved. The key messages in the campaign could help you drive activation rates of 60% or higher. Get the enrollment outcomes you need and provide your customer with an experience that will get high fives all around.
            </Text>
            <Button
              mt={6} 
              onClick={() => setIsPopupOpen(true)}
              colorScheme='teal' 
              size='lg'
            >
              Schedule a demo
            </Button>
          </Stack>
          <AspectRatio ratio={16 / 9} maxW='100%'>
            <Image
              rounded='md'
              alt='feature image'
              src={
                'https://images.unsplash.com/photo-1554200876-56c2f25224fa?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80'
              }
              objectFit='cover'
            />
          </AspectRatio>            
        </SimpleGrid>
        <PopupModal
          open={isPopupOpen}
          onModalClose={() => setIsPopupOpen(false)}
          iframeTitle="Schedule Onboarding"
          pageSettings={{
            backgroundColor: useColorModeValue('ffffff', '1A202C'),
            hideEventTypeDetails: false,
            hideGdprBanner: true,
            hideLandingPageDetails: false,
            primaryColor: '81E6D9',
            textColor: useColorModeValue('1A202C', 'ffffff')
          }}
          url='https://calendly.com/d/crz-vdx-37g/product-walkthrough-new-account-opening'
          rootElement={document.getElementById('root') as HTMLElement}
        />
      </Container>
    )
  }