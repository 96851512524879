import { 
  Box,
  Container,
  Heading,
  SimpleGrid,  
  Text
} from '@chakra-ui/react'
import { RecentReportsTable, DailyStats } from '../../components'

export const DashboardHome: React.FC = () => {
  return (
    <Container maxW={'6xl'} py={12}>
      <Box maxW="7xl" mx={'auto'} pt={5} px={{ base: 2, sm: 12, md: 17 }}>
        <Heading 
          textAlign={'center'}
          fontSize={'4xl'}
          py={10}
          fontWeight={'bold'}>
          Current Autobooks performance highlights
        </Heading>
        <Box px={{base: 20, lg: 20}} pb={16}>
          <Text fontSize='xl' pb={6}>
            These reports will help you build a business case for Autobooks internally, and show  progress that you can bring back to your team.
          </Text>
          <Text fontSize='xl'>
            The reports will help provide transparency into your customer experience so that your customers will be completely satisfied with your Autobooks solution. 
          </Text>
        </Box>           
      </Box>
      <DailyStats />
      <RecentReportsTable />
    </Container>
  )
}