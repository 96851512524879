import { useState } from 'react'
import {
  Box, 
  Button,
  Heading,
  chakra,
  Icon,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  useToast
} from '@chakra-ui/react'
import {  FiStar } from 'react-icons/fi'
import { Link } from './'
import { useAuth0 } from '@auth0/auth0-react'
import { useEffect } from 'react'

const { REACT_APP_AUTH0_DOMAIN, REACT_APP_SMB_UTILITY_API } = process.env


const BASIC_REPORTS = [
  { report: 'Autobooks Stats',           year: '2022', month: 'February', status: 'pending', filename: 'stats.csv', url: null, isPremium: false },
  { report: 'Autobooks SMBs',           year: '2022', month: 'February', status: 'pending', filename: 'smb.csv', url: null, isPremium: false },
  { report: 'Autobooks Stats',           year: '2022', month: 'January',  status: 'pending', filename: 'stats.csv', url: null, isPremium: false },
  { report: 'Autobooks SMBs',           year: '2022', month: 'January',  status: 'pending', filename: 'smb.csv', url: null, isPremium: false },
  { report: 'Autobooks Stats',           year: '2021', month: 'December', status: 'pending', filename: 'stats.csv', url: 'https://example.com/', isPremium: false },
  { report: 'Autobooks SMBs',           year: '2021', month: 'December', status: 'pending', filename: 'smb.csv', url: 'https://example.com/', isPremium: false }
]

export const BasicReportsTable = () => {
  const [reportList, setReportList] = useState([])
  const { isLoading, getAccessTokenSilently } = useAuth0()
  const toast = useToast()

  useEffect(() => {
    const getReports = async () => {
      try {

        const accessToken = await getAccessTokenSilently();

        console.log('[from getReports] accessToken')
        console.log(accessToken);                
  
        const reportResponse = await fetch(`${REACT_APP_SMB_UTILITY_API}/GetReports`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          method: 'POST'
        });

        console.log('[from getReports] reportResponse')
        console.log(reportResponse)
  
        const res = await reportResponse.json()
        console.log('[from getReports] res')
        console.log(res)

      } catch (e) {
        console.log(e)
      }      
    }    
    getReports()
    console.log('[from useEffect] called getReports')
  },[getAccessTokenSilently, isLoading])



  return (
    <Box maxW="7xl" mx={'auto'} pt={5} px={{ base: 2, sm: 12, md: 17 }}>
      <Heading
        textAlign={'center'}
        fontSize={'4xl'}
        py={10}
        fontWeight={'bold'}>
        Basic Reports
      </Heading>
      <Table variant='simple'>
        <Thead>
          <Tr>
            <Th>VIP</Th>
            <Th>Report</Th>
            <Th>Month</Th>
            <Th>Year</Th>
            <Th>Status</Th>            
            <Th isNumeric>Download</Th>
          </Tr>
        </Thead>
        <Tbody>
          {BASIC_REPORTS.map(({report, month, year, status, url, isPremium, filename }, i) => (
            <Tr key={i}>
              <Td>{isPremium
                ? <Icon
                  mr="4"
                  fontSize="16"
                  _groupHover={{
                    color: 'white',
                  }}
                  as={FiStar}
                />
                : null
              }</Td>
              <Td>{report}</Td>
              <Td>{month}</Td>
              <Td>{year}</Td>
              <Td>{status}</Td>
              <Td isNumeric>
                <Button 
                  size='sm' 
                  variant='outline' 
                  colorScheme='purple' 
                  disabled={url ? false : true} 
                  onClick={() => url 
                    ? toast({
                      title: 'Report sent',
                      description: `We have created your report! Check your inbox for an email from reports@autobooks.co with the subject "${year}_${month}_${filename}"`,
                      status: 'success',
                      duration: 9000,
                      isClosable: false,
                    }) : null
                  }
                >
                  {url ? 'Download' : 'Pending...'}
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>        
      </Table>
    </Box>    
  )
}