import { Center, CircularProgress, CircularProgressLabel, VStack } from '@chakra-ui/react'
import { LockSVG } from '../media'

export const Authorizing: React.FC = () => {
  return (
    <Center py={6} height={'100vh'}>
      <VStack>
        <CircularProgress isIndeterminate  capIsRound thickness={3} color='cyan' size={'4em'}>
          <CircularProgressLabel>
            Authorizing...
          </CircularProgressLabel>
        </CircularProgress>
      </VStack>
    </Center>
  )
}