import { useAuth0 } from "@auth0/auth0-react"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Loading } from '../../components'

export const Logout: React.FC = () => {
    const { logout } = useAuth0()
    const navigate = useNavigate()

    useEffect(() => {
        logout()
        navigate('/')
    }, [])


    return <Loading />
}