import React, { useEffect, useState } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { useNavigate } from "react-router-dom"
import { Authorizing } from './index'

type TStatus = 'pending' | 'verified'

interface ProtectProps {
    status?: 'pending' | 'verified'
    role?: 'admin'
    redirectPath?: string
}

// this needs a refactor to focus on permission, but is fine for now
export const Protect: React.FC<ProtectProps> = ({ children, status, role, redirectPath }) => {

    const namespace  = process.env.REACT_APP_AUTH0_NAMESPACE

    const [isLoadingLocal, setIsLoadingLocal] = useState(true)
    const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0()
    const navigate = useNavigate()
    
    useEffect(() => {
        async function checkUser() {
            if (!isLoading && !isAuthenticated) {
                loginWithRedirect();
            } else {
                setIsLoadingLocal(false)
            }
        }
        checkUser();
    }, [user, isAuthenticated, isLoading, loginWithRedirect])

    if (!isLoadingLocal && !isLoading && isAuthenticated) {
        if(!user?.email_verified) {
          navigate('/onboarding/verify-email')
        }
        if(user && !user[`${namespace}/accepted_terms`]) {
          navigate('/onboarding/accept-terms')
        }
        if (status && role && user && user[`${namespace}/status`] !== status && user && user[`${namespace}/role`] !== role) {
            navigate(redirectPath || '/dashboard')        
        }
        if (status && user && user[`${namespace}/status`] !== status) {
            navigate(redirectPath || '/dashboard')        
        }
        if (role && user && user[`${namespace}/role`] !== role) {
            navigate(redirectPath || '/dashboard')        
        }

        return <>{children}</>
    }

    return <Authorizing />

}