import { useNavigate } from 'react-router-dom'
import { Auth0Provider } from "@auth0/auth0-react";

const { REACT_APP_AUTH0_CLIENT_ID, REACT_APP_AUTH0_DOMAIN, REACT_APP_AUTH0_SCOPE, REACT_APP_AUTH0_AUDIENCE } = process.env

export const Auth0ProviderWithHistory: React.FC = ({ children }) => {

  const navigate = useNavigate()

  return (
    <Auth0Provider
      domain={REACT_APP_AUTH0_DOMAIN || ''}
      clientId={REACT_APP_AUTH0_CLIENT_ID || ''}
      redirectUri={window.location.origin}
      onRedirectCallback={() => navigate(window.location.pathname)}
      audience={REACT_APP_AUTH0_AUDIENCE || ''}
      scope={REACT_APP_AUTH0_SCOPE || ''}
    >
      {children}
    </Auth0Provider>
  )
}