import {
  Flex,
  Box,
  Center,
  Checkbox,
  Progress,
  Stack,
  Icon,
  Button,
  Heading,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  UnorderedList,
  ListItem,
  useColorModeValue,
  useToast,
  useDisclosure,
  useInterval
} from '@chakra-ui/react';

import { useAuth0 } from '@auth0/auth0-react'
import { useNavigate } from 'react-router-dom'
import { BsBookmarkCheckFill } from 'react-icons/bs'
import { useState } from 'react';

const { REACT_APP_SMB_UTILITY_API, REACT_APP_IS_BETA, REACT_APP_AUTH0_NAMESPACE } = process.env

export const AcceptTerms: React.FC = () => {
  const [state, setState] = useState({
    accepted_terms: false,
    isLoading: false
  })
  const { user, getAccessTokenSilently } = useAuth0()
  const navigate = useNavigate()

  const acceptTerms = async () => {
    
    try {
      setState({ ...state, isLoading: true })
      const accessToken = await getAccessTokenSilently()
      const res = await fetch(`${REACT_APP_SMB_UTILITY_API}/v1/users`, {
        method: 'POST',
        headers: { Authorization: `Bearer ${accessToken}`},
        body: JSON.stringify({
          accepted_terms: true,
          accepted_terms_version: 'beta v0.1'
        })
      })
      const json = await res.json()
      console.log(json);      
      if(json.message === 'success') {        
        navigate('/dashboard')
      }
    } catch (e) {
      console.log(e);
    }
  }

  // // do this one right away 
  // useEffect(() => {
  //   user && user.email_verified && navigate('/onboarding')
  // }, [user])

  useInterval(() => {
    if(user && user[`${REACT_APP_AUTH0_NAMESPACE}/accepted_terms`]) {
      navigate('/dashboard')
    }
  }, state.isLoading ? 500 : null)  

  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}>
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
        <Stack align={'center'}>
          <Heading fontSize={'2xl'}>One more thing...</Heading>            
        </Stack>
        <Box
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          p={8}>
          <Stack spacing={4}>
            <Center>              
              <Icon as={BsBookmarkCheckFill} w={32} h={32} />
            </Center>
            <Progress size='xs' isIndeterminate />
            <Stack spacing={4}>
              <Text fontSize={'md'}>By using the FI Product Hub, you understand and agree to our <TermsModal linkText={REACT_APP_IS_BETA? 'Early Adopter Terms of Service.' : 'Terms of Service' } /></Text>
              {REACT_APP_IS_BETA
                ? (
                  <Box>
                    <UnorderedList spacing={1}>
                      <ListItem fontSize={'sm'}>Things will change and you may encounter issues</ListItem>
                      <ListItem fontSize={'sm'}>You may be asked for feedback</ListItem>
                      <ListItem fontSize={'sm'}>This overrides any other service level agreements and there is no uptime guarantee</ListItem>
                      <ListItem fontSize={'sm'}>Your participation in the Beta is subject to confidentiality</ListItem>
                      <ListItem fontSize={'sm'}>Participation in the beta does not entitle you to any right to intellectual property or compensation</ListItem>
                      <ListItem fontSize={'sm'}>These terms only apply to your use of the Beta Tool. Any other terms you have agreed to still apply</ListItem>
                    </UnorderedList>
                  </Box>
                ) : null
              }
              <Checkbox 
                size='lg' 
                spacing={6} 
                isRequired 
                value='accepted_terms'
                onChange={(e) => setState({...state, accepted_terms: e.target.checked})}
                py={8}
              >
                I agree to the {REACT_APP_IS_BETA? 'Early Adopter ' : null}Terms of Service for the Autobooks FI Product Hub application.
              </Checkbox>
              <Button 
                isLoading={state.isLoading}
                disabled={!state.accepted_terms}
                colorScheme={'cyan'} 
                onClick={() => acceptTerms()}>
                Accept Terms
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}


interface TermsModalProps {
  linkText: string
}

const TermsModal: React.FC<TermsModalProps> = ({ linkText }) => {
  const [state, setState] = useState({
    accepted_terms: false,
    isLoading: false
  })
  const { user, getAccessTokenSilently } = useAuth0()
  const { isOpen, onClose, onOpen } = useDisclosure()
  const navigate = useNavigate()

  const acceptTerms = async () => {
    
    try {
      setState({ ...state, isLoading: true })
      const accessToken = await getAccessTokenSilently()
      const res = await fetch(`${REACT_APP_SMB_UTILITY_API}/v1/users`, {
        method: 'POST',
        headers: { Authorization: `Bearer ${accessToken}`},
        body: JSON.stringify({
          accepted_terms: true,
          accepted_terms_version: 'beta v0.1'
        })
      })
      const json = await res.json()
      console.log(json);      
      if(json.message === 'success') {        
        navigate('/dashboard')
      }
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <>
      <Button onClick={onOpen} variant='link' textDecoration='underline'>
        {linkText}
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size='4xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{linkText}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Heading>
              BETA TEST PARTICIPANT AGREEMENT
            </Heading>
            <Text py={2}>
              This Beta Participant Agreement (“Agreement”) is made and entered into between (“Participant”) and Autobooks (“Manufacturer”), and is effective as of the date of acceptance by the Participant. 
            </Text>
            <Text py={2}>
              Participant is an individual or business that desires to participate in “beta testing”, which provides access to confidential materials or services currently in development by Manufacturer.Autobooks. Manufacturer Autobooks wishes to obtain the benefit of Participant&apos;s services and reports as a beta tester of manufacturer&apos;s materials or service. In consideration of the premises, and of the mutual covenants and conditions, the parties agree to the following terms and conditions: 
            </Text>
            <Heading size='sm' pt={6} pb={2}>
              Beta Test
            </Heading>
            <Text py={2}>
              Each material or service listed on Exhibit A and all components, accessories, and documentation related to each such Beta Test Material and/or technology provided by Manufacturer including, but not limited to, specifications and other technical information, and all updates or revisions thereto, shall be referred to in the following provisions as the “Beta Test Material.” The parties hereto agree that Participant shall be a beta tester for those Beta Test Materials and services set forth on Exhibit A, as may be amended from time to time by Manufacturer.  
            </Text>
            <Heading size='sm' pt={6} pb={2}>
              License
            </Heading>
            <Text py={2}>
              Manufacturer hereby grants Participant a non-exclusive, non-transferable consent and license to use each Beta Test Material solely for the purpose of evaluating and testing the Beta Test Material for Manufacturer as described in Exhibit A. The Beta Test Material may not be publicly disclosed, sublicensed, sold, assigned, leased, loaned, or otherwise transferred by Participant to any third party within or outside of Participant&apos;s organization for any reason. 
            </Text>
            <Heading size='sm' pt={6} pb={2}>
              Manufacturer&apos;s Obligations
            </Heading>
            <Text py={2}>
              a. Manufacturer will deliver the Beta Test Material to Participant at Manufacturer&apos;s expense.<br />
              b. Manufacturer will provide the support or warranty service for the Beta Test Material described in the Exhibit and no other support or services. 
              c. Manufacturer has no obligation to develop or provide any updates or revisions to the Beta Test Material, and Manufacturer reserves the right to alter or adjust performance specifications for the Beta Test Material as it deems necessary or desirable. 
            </Text>
            <Heading size='sm' pt={6} pb={2}>
              Participant&apos;s Obligations
            </Heading>
            <Text py={2}>
              a. Participant agrees to test and evaluate the Beta Test Material as requested and described in Exhibit A. Participant agrees to familiarize itself with the Beta Test Material information provided by Manufacturer and to only use or test the Beta Test Material as directed.  Participant will notify Manufacturer of any and all functional flaws, errors, anomalies, and problems directly or indirectly associated with the Beta Test Material known to or discovered by Participant. In addition, Participant agrees to provide Manufacturer with such reports via the methods designated by Manufacturer and to promptly respond to any and all reasonable inquiries, questionnaires, surveys and other test documents submitted to Participant by Manufacturer. 
            </Text>
            <Text py={2}>
              b. If Participant is a company or other entity, Participant shall designate to Manufacturer, in writing, an employee or representative who will serve as the single technical contact at the Beta site for the Beta Test Material, and who will be responsible for maintaining communication with the Manufacturer Beta site team on a regular basis. In addition, the Beta site address, where Participant will test the Beta Test Material, will be given to Manufacturer in writing. If Participant changes either its technical contact person or Beta site address, it will promptly notify Manufacturer in writing of such change. 
            </Text>
            <Text py={2}>
              c. Any feedback, ideas, modifications, suggestions, improvements, and the like made by Participant with respect to the Beta Test Material (“Supportive Information”) will be the property of Manufacturer. Participant agrees to assign, and hereby assigns, all right, title, and interest worldwide in the Supportive Information and the related intellectual property rights to Manufacturer and agrees to assist Manufacturer, at Manufacturer&apos;s expense, in perfecting and enforcing such rights. Manufacturer may disclose or use Supportive Information for any purposes whatsoever without any obligation to Participant.
            </Text>
            <Text py={2}>
              d. Participant agrees to pay all incidental costs (such as, costs for Internet and phone services, accessories, cabling, etc.) associated with the testing of the Beta Test Material and incurred during Participant&apos;as possession of the Beta Test Material, unless otherwise agreed to in writing by both parties and described in Exhibit A.  
            </Text>
            <Heading size='sm' pt={6} pb={2}>
              Proprietary Rights; No Right to Copy, Modify, or Disassemble. 
            </Heading>
            <Text py={2}>
              The Beta Test Material provided by Manufacturer and all copies thereof, are proprietary to and the property of Manufacturer. All applicable rights in all copyrights, trademarks, trade secrets, trade names, patents, and other intellectual property rights in or associated with the Beta Test Material are and will remain in Manufacturer and Participant shall have no such intellectual property rights in the Beta Test Material. 
            </Text>
            <Text py={2}>
              Participant may not copy or reproduce the Beta Test Material without Manufacturer&apos;s prior written consent, except as reasonably needed to perform its obligations hereunder and subject to the following restrictions. Participant may not copy or reproduce any software or documentation provided by Manufacturer, without Manufacturer&apos;s prior consent, except as is reasonably needed to perform Participant&apos;s obligations under this Agreement. Each copy of software or documentation made by Participant must contain Manufacturer&apos;s proprietary and copyright notices in the same form as on the original. Participant shall not remove or deface any portion of any legend provided on any part of the Beta Test Material. 
            </Text>
            <Text py={2}>
              Participant agrees to secure and protect the Beta Test Material and all copies thereof in a manner consistent with the maintenance of Manufacturer&apos;s rights therein and to take appropriate actions by instruction or agreement with any of its employees or agents permitted access thereto to satisfy its obligations hereunder. 
            </Text>
            <Text py={2}>
              Participant shall not reverse engineer, alter, modify, disassemble or decompile the Beta Test Material, or any part thereof, without Manufacturer&apos;s prior written consent. 
            </Text>
            <Heading size='sm' pt={6} pb={2}>
              Term and Termination
            </Heading>
            <Text py={2}>
              The term of this Agreement shall begin on the date set forth above (or, if no date is given, then the date both parties accept this Agreement by written signature) and shall continue until terminated as set forth below.  
            </Text>
            <Text py={2}>
              This Agreement may be terminated at any time for any reason by either party giving ten days prior written notice to the other party, subject to Section 8.3 below. A particular Beta Test Material Agreement shall automatically terminate upon the general release to the public of the final product derived from the Beta Test Material by Manufacturer, or sooner upon ten days prior written notice by either party. The parties acknowledge that Manufacturer is under no obligation to release any final product or Beta Test Material to the public.               
            </Text>
            <Text py={2}>
              Upon termination of this Agreement or a particular Beta Test Material Agreement, Participant agrees to (a) return the Beta Test Material and all copies thereof to Manufacturer, if requested by Manufacturer in writing to do so, within seven days after such termination, or (b) if requested by Manufacturer to do so, certify to Manufacturer in writing that the Beta Test Material and all copies thereof have been destroyed, or (c) purchase the Beta Test Material as set forth in Section 4.3. The provisions of, and the obligations of the parties under, Sections 4.3, 4.4, 5, 6, 7, 8, and 9, and any other provisions that would normally survive, shall survive the termination of this Agreement. 
            </Text>
          </ModalBody>
          <ModalFooter>
            <Text>By clicking &apos;Accept Terms&apos; I agree to the {REACT_APP_IS_BETA? 'Early Adopter ' : null}Terms of Service for the Autobooks FI Product Hub application</Text>
            <Button isLoading={state.isLoading} colorScheme='blue' onClick={() => acceptTerms()}>Accept Terms</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}