const { REACT_APP_SMB_UTILITY_API } = process.env

export interface CreateTicketArgs {
  token: string
  properties: {
    subject: string
    content: string
    category?: string
  }  
}

export const create = async ({
  token,
  properties: {
    subject,
    content,
    category
  }
} : CreateTicketArgs) => {
  console.log('hello from create');
  
  const res = await fetch(`${REACT_APP_SMB_UTILITY_API}/v1/tickets`, {
    method: 'post', 
    headers: {
      authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      subject,
      content, 
      category
    })
  })
  const response = await res.json()
  console.log('response');
  console.log(response);
  
  return await res.json()
}