import { useState } from 'react'
import {
    Container,
    SimpleGrid,
    Image,
    Flex,
    Heading,
    Text,
    Stack,
    StackDivider,
    Icon,
    useColorModeValue,
    AspectRatio,
    Button
  } from '@chakra-ui/react'
  import { PopupModal } from 'react-calendly'
  
  export const SmallBusinessApply = () => {

    const [isPopupOpen, setIsPopupOpen] = useState(false)

    return (
      <Container maxW={'6xl'} py={12}>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
          <Stack spacing={4}>
            <Text
              textTransform={'uppercase'}
              color={'blue.400'}
              fontWeight={600}
              fontSize={'sm'}
              bg={useColorModeValue('blue.50', 'blue.900')}
              p={2}
              alignSelf={'flex-start'}
              rounded={'md'}>
              Our Story
            </Text>
            <Heading>Easily allow your customers to open a new account <i>and get paid</i> with Autobooks.</Heading>
            <Text color={'gray.500'} fontSize={'lg'}>
            The Online Account Opening widget is an embeddable snippet that lets your customer open a new account and sign up for Autobooks at the same time. Simply take the embeddable snippet and install on your site as easily as you can embed a YouTube video.
            </Text>
            <Button
              mt={6} 
              onClick={() => setIsPopupOpen(true)}
              colorScheme='teal' 
              size='lg'
            >
              Schedule a demo
            </Button>
          </Stack>
          <AspectRatio ratio={16 / 9} maxW='100%'>
            <Image
              rounded='md'
              alt='feature image'
              src={
                'https://images.unsplash.com/photo-1554200876-56c2f25224fa?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80'
              }
              objectFit='cover'
            />
          </AspectRatio>            
        </SimpleGrid>
        <PopupModal
          open={isPopupOpen}
          onModalClose={() => setIsPopupOpen(false)}
          iframeTitle="Schedule Onboarding"
          pageSettings={{
            backgroundColor: useColorModeValue('ffffff', '1A202C'),
            hideEventTypeDetails: false,
            hideGdprBanner: true,
            hideLandingPageDetails: false,
            primaryColor: '81E6D9',
            textColor: useColorModeValue('1A202C', 'ffffff')
          }}
          url="https://calendly.com/d/cpb-7bc-3hs/competitive-displacement-campaign"
          rootElement={document.getElementById('root') as HTMLElement}
        />
      </Container>
    )
  }